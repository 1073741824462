export enum UserStatus {
  Active = 'Active',
  Pending = 'Pending',
  Inactive = 'Inactive',
  Blocked = 'Blocked',
  Resendactivation = 'Resendactivation'
}

export enum UserAction {
  Edit = 'Edit'
}

// Define a type for valid user statuses
export type IUserStatus =
  | UserStatus.Pending
  | UserStatus.Active
  | UserStatus.Inactive
  | UserStatus.Blocked
  | UserStatus.Resendactivation;
