// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --testing` replaces `environment.ts` with `environment.testing.ts`.
// The list of file replacements can be found in `angular.json`.

import { EnvType, EnvTypeConfig } from '@/_types/enums/envType.enum';

export const environment = {
  production: false,
  envType: new EnvTypeConfig(EnvType.TYPE.PRODUCTION),
  NovatiqAPIKey: '',
  NovatiqAPIURL: 'https://api.production.fusion-platform.com/production/',
  NovatiqEncryptionKey: 'VRqyHh7$#@$^@0120ERF'
};
