import {
  FirstLetterPipe,
  NovatiqEmailMask,
  NovatiqHighLightSearch,
  NovatiqMinutesToHours,
  OrderByPipe
} from '@/_pipes';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// import ngx-translate and the http loader
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AngularSplitModule } from 'angular-split';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { TableModule } from 'primeng/table';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-om-perfect-scrollbar';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { CardModule } from 'primeng/card';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DividerModule } from 'primeng/divider';
import { DialogService } from 'primeng/dynamicdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SliderModule } from 'primeng/slider';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import {
  NovatiqAlertComponent,
  NovatiqAnchorTagComponent,
  NovatiqButtonComponent,
  NovatiqCaptchaComponent,
  NovatiqConfirmationDialogComponent,
  NovatiqCustomColumnDirective,
  NovatiqCustomDataTableComponent,
  NovatiqCustomDropdownComponent,
  NovatiqCustomExpandableRowDirective,
  NovatiqCustomRowComponent,
  NovatiqDatatableComponent,
  NovatiqDropdownComponent,
  NovatiqExpiryLockComponent,
  NovatiqFormInputComponent,
  NovatiqFormInputGroupComponent,
  NovatiqListViewComboxComponent,
  NovatiqListViewComponent,
  NovatiqLoadingSpinnerComponent,
  NovatiqPopoverComponent,
  NovatiqPopupComponent,
  NovatiqTabComponent,
  NovatiqTabsComponent,
  NovatiqUnsavedChangesConfirmationComponent,
  NovatiqVerify2FAComponent,
  PriceBandFormComponent
} from './components';
import { NovatiqAutoFocusDirective, NovatiqCopyClipboardDirective } from './directive';
import { FieldPipe } from './field.pipe';
import { FriendlyOrgTypePipe } from './friendly-org-type.pipe';

const PRIMENG_MODULES = [
  ButtonModule,
  CalendarModule,
  CheckboxModule,
  ChipsModule,
  ConfirmDialogModule,
  DialogModule,
  DropdownModule,
  FieldsetModule,
  InputNumberModule,
  InputTextModule,
  MultiSelectModule,
  ChipsModule,
  TabViewModule,
  TooltipModule,
  OrganizationChartModule,
  PanelModule,
  SelectButtonModule,
  TabViewModule,
  TableModule,
  TagModule,
  ToastModule,
  ToolbarModule,
  CardModule,
  SliderModule,
  InputSwitchModule,
  PasswordModule,
  ConfirmPopupModule,
  AvatarModule,
  ToggleButtonModule,
  ChipModule,
  OverlayPanelModule,
  DividerModule,
  InputTextareaModule,
  AccordionModule,
  ListboxModule,
  MenuModule
];

@NgModule({
  declarations: [
    OrderByPipe,
    NovatiqAlertComponent,
    NovatiqAnchorTagComponent,
    NovatiqButtonComponent,
    NovatiqCaptchaComponent,
    NovatiqCustomDataTableComponent,
    NovatiqCustomDropdownComponent,
    NovatiqDatatableComponent,
    NovatiqDropdownComponent,
    NovatiqExpiryLockComponent,
    NovatiqEmailMask,
    NovatiqMinutesToHours,
    NovatiqFormInputComponent,
    NovatiqFormInputGroupComponent,
    NovatiqListViewComponent,
    NovatiqPopupComponent,
    NovatiqPopoverComponent,
    NovatiqLoadingSpinnerComponent,
    NovatiqTabComponent,
    NovatiqTabsComponent,
    NovatiqListViewComboxComponent,
    NovatiqUnsavedChangesConfirmationComponent,
    NovatiqCustomColumnDirective,
    NovatiqCustomExpandableRowDirective,
    NovatiqCustomRowComponent,
    NovatiqHighLightSearch,
    NovatiqCopyClipboardDirective,
    NovatiqConfirmationDialogComponent,
    NovatiqVerify2FAComponent,
    NovatiqAutoFocusDirective,
    FieldPipe,
    FirstLetterPipe,
    FriendlyOrgTypePipe,
    PriceBandFormComponent
  ],
  exports: [
    TranslateModule,
    OrderByPipe,
    NovatiqAlertComponent,
    NovatiqButtonComponent,
    NovatiqAnchorTagComponent,
    NovatiqCaptchaComponent,
    NovatiqCustomDataTableComponent,
    NovatiqCustomDropdownComponent,
    NovatiqDatatableComponent,
    NovatiqDropdownComponent,
    NovatiqEmailMask,
    NovatiqMinutesToHours,
    NovatiqExpiryLockComponent,
    NovatiqFormInputComponent,
    NovatiqFormInputGroupComponent,
    NovatiqListViewComponent,
    NovatiqPopupComponent,
    NovatiqPopoverComponent,
    NovatiqListViewComboxComponent,
    NovatiqTabComponent,
    NovatiqLoadingSpinnerComponent,
    NovatiqTabsComponent,
    NovatiqUnsavedChangesConfirmationComponent,
    CommonModule,
    FormsModule,
    ModalModule,
    ReactiveFormsModule,
    NovatiqCustomColumnDirective,
    NovatiqCustomExpandableRowDirective,
    NovatiqCustomRowComponent,
    NovatiqHighLightSearch,
    DragDropModule,
    NgxSpinnerModule,
    NovatiqCopyClipboardDirective,
    AngularSplitModule,
    NovatiqConfirmationDialogComponent,
    NovatiqVerify2FAComponent,
    NovatiqAutoFocusDirective,
    FieldPipe,
    FirstLetterPipe,
    FriendlyOrgTypePipe,
    PriceBandFormComponent,
    ...PRIMENG_MODULES
  ],
  imports: [
    TranslateModule,
    CommonModule,
    FormsModule,
    ModalModule.forRoot(),
    NgxSpinnerModule,
    PerfectScrollbarModule,
    PopoverModule.forRoot(),
    ReactiveFormsModule,
    NgxDatatableModule,
    DragDropModule,
    AngularSplitModule,
    ...PRIMENG_MODULES
  ],
  providers: [ConfirmationService, DialogService]
})
export class SharedModule {}
